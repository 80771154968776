<template>
    <div class="page_item" v-if="!isDropdown">
        <div class="form_grp m-0 p-0">
            <div class="group_item">
                <div class="field_wpr">
                    <multiselect
                        v-model="perPage"
                        :options="options"
                        class="small"
                        valueProp="value"
                        label="label"
                    ></multiselect>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isDropdown" class="dropdown_wpr" :class="dropdown ? 'active' : ''">
        <ul>
            <li v-for="(option, o) of options" :key="o" @click.self="updateFilter(option.value)">{{ option.label }}</li>
        </ul>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'

    export default {
        name: 'Page Filter',

        data () {
            return {
                options: [
                    { value: 6, label: '6' },
                    { value: 12, label: '12' },
                    { value: 24, label: '24' },
                    { value: 48, label: '48' },
                    { value: 96, label: '96' }
                ],
                perPage: 5,
                dropdown: false,
            };
        },

        props: {
            modelValue: Number,
            type: {
                type: Number,
                default: 1,
            },
            isDropdown: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['update:modelValue'],

        components:{
            Multiselect
        },

        watch: {
            perPage (page) {
                const vm = this;

                vm.$emit('update:modelValue', page);

                setTimeout(function () {
                    vm.dropdown = false;
                }, 1);
            },
        },

        mounted () {
            const vm = this;

            vm.setPageType();
        },

        methods: {
            updateFilter (page) {
                const vm = this;

                vm.$emit('update:modelValue', page);

                setTimeout(function () {
                    vm.dropdown = false;
                }, 1);

                vm.perPage = page;
            },

            setPageType () {
                const vm = this;

                if (vm.type == 1) {
                    vm.perPage = 6;
                    vm.options = [
                        { value: 6, label: '6' },
                        { value: 12, label: '12' },
                        { value: 24, label: '24' },
                        { value: 48, label: '48' },
                        { value: 96, label: '96' }
                    ];
                } else if (vm.type == 2) {
                    vm.perPage = 5;
                    vm.options = [
                        { value: 5, label: '5' },
                        { value: 10, label: '10' },
                        { value: 25, label: '25' },
                        { value: 50, label: '50' },
                        { value: 100, label: '100' },
                        { value: 250, label: '250' },
                        { value: 500, label: '500' },
                    ];
                }
            },
        },
    }
</script>
